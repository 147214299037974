import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-cy"]
const _hoisted_2 = ["checked", "name", "value", "data-cy"]
const _hoisted_3 = { class: "acRadio_label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "acRadio",
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.change && _ctx.change(...args))),
    "data-cy": `asRadio-${_ctx.name}`
  }, [
    _createElementVNode("input", {
      class: "acRadio_input",
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.change && _ctx.change(...args))),
      checked: _ctx.checked,
      name: _ctx.name,
      value: _ctx.modelValue,
      type: "radio",
      "data-cy": `radio-${_ctx.name}`
    }, null, 40, _hoisted_2),
    _createElementVNode("label", _hoisted_3, [
      _renderSlot(_ctx.$slots, "label")
    ])
  ], 8, _hoisted_1))
}