import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bc5e27e8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "acProfile" }
const _hoisted_2 = {
  class: "acProfile_title",
  "data-cy": "headerEditTitle"
}
const _hoisted_3 = { class: "acProfile_row -align-center -justify-center" }
const _hoisted_4 = {
  key: 0,
  class: "acProfile_cell acProfile_username username"
}
const _hoisted_5 = { class: "username_name" }
const _hoisted_6 = { class: "username_email" }
const _hoisted_7 = { class: "acProfile_row" }
const _hoisted_8 = { class: "acProfile_row" }
const _hoisted_9 = { class: "acProfile_row" }
const _hoisted_10 = { class: "acProfile_row" }
const _hoisted_11 = { class: "acProfile_row" }
const _hoisted_12 = { class: "acProfile_label" }
const _hoisted_13 = { class: "acProfile_row -mb0" }
const _hoisted_14 = { class: "acProfile_row -space-between -align-center" }
const _hoisted_15 = { class: "acProfile_cell gender" }
const _hoisted_16 = { class: "gender_label" }
const _hoisted_17 = { class: "gender_radios" }
const _hoisted_18 = { class: "acProfile_cell -actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_avatar_upload = _resolveComponent("avatar-upload")!
  const _component_ac_input = _resolveComponent("ac-input")!
  const _component_v_field = _resolveComponent("v-field")!
  const _component_phone_input = _resolveComponent("phone-input")!
  const _component_ac_select = _resolveComponent("ac-select")!
  const _component_ac_notice = _resolveComponent("ac-notice")!
  const _component_ac_radio = _resolveComponent("ac-radio")!
  const _component_ac_button = _resolveComponent("ac-button")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_settings_content = _resolveComponent("settings-content")!

  return (_openBlock(), _createBlock(_component_settings_content, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t(_ctx.title)), 1),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_avatar_upload, {
            src: _ctx.user.avatar,
            onChange: _ctx.uploadAvatar,
            loading: !_ctx.saveAvatar,
            class: "acProfile_cell"
          }, null, 8, ["src", "onChange", "loading"]),
          (!_ctx.update)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.username), 1),
                _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.user.email), 1)
              ]))
            : _createCommentVNode("", true)
        ]),
        _createVNode(_component_v_form, {
          onSubmit: _ctx.submit,
          onInput: _ctx.clear
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_v_field, {
                modelValue: _ctx.user.firstName,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.user.firstName) = $event)),
                modelModifiers: { trim: true },
                name: "name",
                label: _ctx.$t('firstName'),
                rules: "required"
              }, {
                default: _withCtx(({ errors }) => [
                  _createVNode(_component_ac_input, {
                    name: "name",
                    error: _ctx.getFirstError('name', errors),
                    modelValue: _ctx.user.firstName,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.user.firstName) = $event)),
                    modelModifiers: { trim: true },
                    class: "acProfile_cell",
                    label: _ctx.$t('firstName'),
                    note: `(${_ctx.$t('required')})`,
                    placeholder: _ctx.$t('input.firstName'),
                    maxlength: "150"
                  }, null, 8, ["error", "modelValue", "label", "note", "placeholder"])
                ]),
                _: 1
              }, 8, ["modelValue", "label"]),
              _createVNode(_component_v_field, {
                modelValue: _ctx.user.lastName,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.user.lastName) = $event)),
                modelModifiers: { trim: true },
                name: "lastName",
                label: _ctx.$t('lastName')
              }, {
                default: _withCtx(({ errors }) => [
                  _createVNode(_component_ac_input, {
                    name: "lastName",
                    error: _ctx.getFirstError('lastName', errors),
                    modelValue: _ctx.user.lastName,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.user.lastName) = $event)),
                    modelModifiers: { trim: true },
                    class: "acProfile_cell",
                    label: _ctx.$t('lastName'),
                    placeholder: _ctx.$t('input.lastName'),
                    maxlength: "150"
                  }, null, 8, ["error", "modelValue", "label", "placeholder"])
                ]),
                _: 1
              }, 8, ["modelValue", "label"])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_ac_input, {
                name: "email",
                "model-value": _ctx.user.email,
                class: "acProfile_cell",
                label: _ctx.$t('email'),
                readonly: ""
              }, null, 8, ["model-value", "label"])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_v_field, {
                "model-value": _ctx.user.phone,
                name: "phone",
                label: _ctx.$t('phone')
              }, {
                default: _withCtx(({ errors }) => [
                  _createVNode(_component_phone_input, {
                    error: _ctx.getFirstError('phone', errors),
                    "model-value": _ctx.user.phone || '',
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.onInputPhone($event))),
                    class: "acProfile_cell"
                  }, null, 8, ["error", "model-value"])
                ]),
                _: 1
              }, 8, ["model-value", "label"])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_v_field, {
                modelValue: _ctx.user.city,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.user.city) = $event)),
                modelModifiers: { trim: true },
                name: "city",
                label: _ctx.$t('city')
              }, {
                default: _withCtx(({ errors }) => [
                  _createVNode(_component_ac_input, {
                    name: "city",
                    error: _ctx.getFirstError('city', errors),
                    modelValue: _ctx.user.city,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.user.city) = $event)),
                    modelModifiers: { trim: true },
                    class: "acProfile_cell",
                    label: _ctx.$t('city'),
                    placeholder: _ctx.$t('input.city'),
                    maxlength: "100"
                  }, null, 8, ["error", "modelValue", "label", "placeholder"])
                ]),
                _: 1
              }, 8, ["modelValue", "label"]),
              _createVNode(_component_v_field, {
                modelValue: _ctx.birthday,
                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.birthday) = $event)),
                modelModifiers: { trim: true },
                name: "birthday",
                label: _ctx.$t('birthday')
              }, {
                default: _withCtx(({ errors }) => [
                  _createVNode(_component_ac_input, {
                    name: "birthday",
                    error: _ctx.getFirstError('birthday', errors),
                    modelValue: _ctx.birthday,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.birthday) = $event)),
                    modelModifiers: { trim: true },
                    class: "acProfile_cell birthday",
                    label: _ctx.$t('birthday'),
                    placeholder: _ctx.$t('input.birthday'),
                    type: "date",
                    onChange: _cache[8] || (_cache[8] = ($event: any) => (_ctx.onInputBirthday($event)))
                  }, null, 8, ["error", "modelValue", "label", "placeholder"])
                ]),
                _: 1
              }, 8, ["modelValue", "label"])
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createVNode(_component_v_field, {
                modelValue: _ctx.user.timezone,
                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.user.timezone) = $event)),
                name: "timezone",
                label: _ctx.$t('timezone')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ac_select, {
                    "data-cy": "user_profile_timezone",
                    modelValue: _ctx.user.timezone,
                    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.user.timezone) = $event)),
                    options: _ctx.allTimezones,
                    search: "",
                    placeholder: _ctx.$t('timezone'),
                    "search-placeholder": _ctx.$t('input.timezone'),
                    class: "acProfile_cell"
                  }, {
                    label: _withCtx(() => [
                      _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.$t('timezone')), 1)
                    ]),
                    _: 1
                  }, 8, ["modelValue", "options", "placeholder", "search-placeholder"])
                ]),
                _: 1
              }, 8, ["modelValue", "label"])
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createVNode(_component_ac_notice, {
                class: "acProfile_notice -error",
                notice: _ctx.notice
              }, null, 8, ["notice"])
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.$t('input.gender')), 1),
                _createElementVNode("div", _hoisted_17, [
                  _createVNode(_component_ac_radio, {
                    name: "gender",
                    "model-value": "M",
                    checked: _ctx.user.gender === 'M',
                    onChange: _cache[12] || (_cache[12] = ($event: any) => (_ctx.user.gender = $event)),
                    class: "gender_radio"
                  }, {
                    label: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('gender.M')), 1)
                    ]),
                    _: 1
                  }, 8, ["checked"]),
                  _createVNode(_component_ac_radio, {
                    name: "gender",
                    "model-value": "F",
                    checked: _ctx.user.gender === 'F',
                    onChange: _cache[13] || (_cache[13] = ($event: any) => (_ctx.user.gender = $event)),
                    class: "gender_radio"
                  }, {
                    label: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('gender.F')), 1)
                    ]),
                    _: 1
                  }, 8, ["checked"])
                ])
              ]),
              _createElementVNode("div", _hoisted_18, [
                _createVNode(_component_ac_button, {
                  "data-text": _ctx.button,
                  "data-cy": "userProfile_save",
                  class: "acProfile_save",
                  type: "submit",
                  onClick: _ctx.scrollToError
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.button), 1)
                  ]),
                  _: 1
                }, 8, ["data-text", "onClick"])
              ])
            ])
          ]),
          _: 1
        }, 8, ["onSubmit", "onInput"])
      ])
    ]),
    _: 1
  }))
}