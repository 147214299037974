import { useStore } from '@web/store'

import { ref, computed } from 'vue'
import FileApi from '@web/api/modules/File'
import IMask from 'imask'
import Locale from '@web/common/locale'
import { IS_MOBILE } from '@web/common/config'
import { User } from '@web/store/types/modules/user'
import { isOffline } from '@web/common/Utils'
import Swal from 'sweetalert2'
import UserMapper from '@web/mapper/UserMapper'
import Logger from '@web/common/Logger'
import allTimezones from '@common/timezoneNames.json'
import i18n from '@web/plugins/i18n'
import Time from '@web/common/Time'

const store = useStore()

interface Phone {
  countryCode: string;
  isValid: boolean;
  countryCallingCode?: string;
  e164?: string;
  formatInternational?: string;
  formatNational?: string;
  formattedNumber?: string;
  nationalNumber?: string;
  phoneNumber?: string;
  type?: string | undefined;
  uri?: string;
}

function useUserProfile ({
  serverErrors,
  displayServerErrors,
  emit
}) {
  const user = ref<User>({} as User)
  user.value = { ...user.value, ...store.getters['user/user'] }
  const username = computed(() => store.getters['user/username'])

  const avatarFileId = ref<number>(0)
  const notice = ref<string | string[]>('')
  const save = ref(true)
  const saveAvatar = ref(true)
  const birthday = computed({
    get () {
      if (!user.value.birthday) onInputBirthday('')
      return user.value.birthday
    },
    set (newDate) {
      user.value.birthday = newDate
    }
  })
  const birthdayMask = {
    mask: Date,
    lazy: false,
    overwrite: true,
    autofix: false,
    blocks: {
      d: {
        mask: IMask.MaskedRange,
        placeholderChar: Locale.get() === 'ru' ? 'д' : 'd',
        from: 1,
        to: 31,
        maxLength: 2
      },
      m: {
        mask: IMask.MaskedRange,
        placeholderChar: Locale.get() === 'ru' ? 'м' : 'm',
        from: 1,
        to: 12,
        maxLength: 2
      },
      Y: {
        mask: IMask.MaskedRange,
        placeholderChar: Locale.get() === 'ru' ? 'г' : 'y',
        from: 1900,
        to: new Date().getFullYear(),
        maxLength: 4
      }
    }
  }

  const button = computed(() => {
    if (IS_MOBILE) {
      return save.value ? i18n.global.t('saveChanges') : i18n.global.t('savingChanges')
    }
    return save.value ? i18n.global.t('Save') : i18n.global.t('saving')
  })

  function clear () {
    notice.value = ''
  }

  function uploadAvatar ($event) {
    saveAvatar.value = false
    FileApi.upload($event)
      .then(({ data }) => {
        avatarFileId.value = data.id

        return store.dispatch('user/avatar', { avatar_file_id: data.id })
      })
      .then(_user => {
        user.value.avatar = _user.avatar
        saveAvatar.value = true

        if (notice.value === i18n.global.t('error.waitLoadAvatar')) {
          notice.value = ''
        }
      })
  }

  const newPhone = ref<Phone | null>(null)

  function onInputPhone (payload) {
    newPhone.value = payload
    user.value.phone = payload.e164 || ''

    if (!payload.isValid) {
      serverErrors.value = {
        ...serverErrors.value,
        phone: [i18n.global.t('error.phoneNotValid')]
      }
    } else {
      serverErrors.value = {
        ...serverErrors.value,
        phone: []
      }
    }
  }

  function onInputBirthday (payload) {
    if (!Time(payload, 'YYYY-MM-DD').isValid()) {
      serverErrors.value = {
        ...serverErrors.value,
        birthday: [i18n.global.t('error.birthdayNotValid')]
      }
    } else {
      serverErrors.value = {
        ...serverErrors.value,
        birthday: []
      }
    }
  }

  function isFormValid () {
    if (Object.keys(serverErrors.value).length === 0) {
      return true
    }

    return Object.values(serverErrors.value).every(value => Array.isArray(value) && value.length === 0)
  }

  async function submit () {
    try {
      if (isOffline()) {
        Swal.fire({
          icon: 'error',
          title: i18n.global.t('error.error'),
          text: i18n.global.t('error.offline')
        })
        return
      }

      if (!isFormValid()) return

      if (!newPhone.value?.isValid) {
        notice.value = i18n.global.t('error.phoneNotValid')
        return
      }

      if (!saveAvatar.value) {
        notice.value = i18n.global.t('error.waitLoadAvatar')
        return
      }

      if (!save.value) return
      save.value = false

      const _user = UserMapper.toServer({
        ...user.value,
        phone: newPhone.value.e164
      })

      await store.dispatch('user/update', _user)
      notice.value = i18n.global.t('profileUpdated')

      Swal.fire({
        icon: 'success',
        title: notice.value,
        timer: 3000,
        showConfirmButton: false
      })
      emit('submit')
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      if (e.errors) {
        serverErrors.value = e.errors
        notice.value = displayServerErrors(e.errors)
      } else {
        notice.value = i18n.global.t('error.userProfile')
      }

      Swal.fire({
        title: i18n.global.t('error.error'),
        text: Array.isArray(notice.value) ? notice.value.join(', ') : notice.value,
        icon: 'error',
        timer: 3000,
        showConfirmButton: false
      })

      Logger.error(e)
    } finally {
      save.value = true
    }
  }

  return {
    user,
    username,
    avatarFileId,
    notice,
    save,
    saveAvatar,
    button,
    clear,
    uploadAvatar,
    birthday,
    birthdayMask,
    onInputBirthday,
    allTimezones,
    newPhone,
    onInputPhone,
    submit
  }
}

export default useUserProfile
