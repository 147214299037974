
import { defineComponent, ref, computed } from 'vue'
import { MqResponsive } from 'vue3-mq'

import AsHeaderMobile from '@web/components/headers/AsHeaderMobile.vue'
import AsHeaderMain from '@web/components/headers/AsHeaderMain.vue'
import AsMenu from '@web/components/AsMenu.vue'
import AsSpacer from '@web/components/AsSpacer.vue'
import Profile from '@web/components.v2/Settings/Profile/index.vue'
import AsFooter from '@web/components/AsFooter.vue'
import AsBreadcrumb from '@web/components/AsBreadcrumb.vue'
import AsBannersTop from '@web/components/AsBannersTop.vue'
import AsBanner from '@web/components/AsBanner.vue'

import i18n from '@web/plugins/i18n'
import { useStore } from '@web/store'
import { useMeta } from '@web/common/vueMeta'
import { useSettingsBanners } from '@web/composition/useBanners'

export default defineComponent({
  components: {
    AsHeaderMobile,
    MqResponsive,
    AsHeaderMain,
    AsMenu,
    Profile,
    AsSpacer,
    AsFooter,
    AsBreadcrumb,
    AsBannersTop,
    AsBanner
  },
  setup () {
    const store = useStore()

    const title = ref('crumb.editInformation')
    const menu = computed(() => store.getters['menu/general'])
    useMeta({
      title: i18n.global.t(title.value)
    })

    return {
      title,
      menu,
      ...useSettingsBanners()
    }
  }
})
