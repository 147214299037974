import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "acPhoneInput" }
const _hoisted_2 = { class: "acPhoneInput_label" }
const _hoisted_3 = {
  key: 0,
  class: "acPhoneInput_note"
}
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_maz_phone_number_input = _resolveComponent("maz-phone-number-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
      (_ctx.note)
        ? (_openBlock(), _createElementBlock("small", _hoisted_3, _toDisplayString(_ctx.note), 1))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_maz_phone_number_input, {
      name: "phone",
      error: _ctx.isError,
      "model-value": _ctx.modelValue,
      class: "acPhoneInput_input",
      placeholder: _ctx.placeholder,
      required: _ctx.required,
      "border-radius": 0,
      onUpdate: _ctx.onInput,
      type: "tel",
      "preferred-countries": _ctx.preferredCountries,
      "default-country-code": _ctx.defaultCountry,
      position: _ctx.position
    }, null, 8, ["error", "model-value", "placeholder", "required", "onUpdate", "preferred-countries", "default-country-code", "position"]),
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "acPhoneInput_error",
          innerHTML: _ctx.error
        }, null, 8, _hoisted_4))
      : _createCommentVNode("", true)
  ]))
}