
import { defineComponent, ref, computed, nextTick, watch, onMounted } from 'vue'

import { fetchCountryCode, browserLocale, getResultsFromPhoneNumber, getAsYouTypeFormat, isCountryAvailable } from './utils'
import { countries } from './phoneCodeCountries'
import examples from 'libphonenumber-js/examples.mobile.json'
import { getExampleNumber, getCountryCallingCode } from 'libphonenumber-js'
import locales from './locales'
import MazInput from '@web/components/maz/MazInput.vue'
import MazSelect from '@web/components/maz/MazSelect.vue'
import { v4 as uuidv4 } from 'uuid'
import { CountryCode } from 'libphonenumber-js/types'

export default defineComponent({
  name: 'MazPhoneNumberInput',
  components: {
    MazInput,
    MazSelect
  },
  props: {
    modelValue: {
      validator: (prop: string | number) => ['string', 'number'].includes(typeof prop) || prop === null,
      default: null
    },
    id: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    // set default phone number (Ex: `default-phone-number="0658585858"`)
    defaultPhoneNumber: { type: String, default: '' },
    // set default country code (Ex: `default-country-code="FR"`)
    defaultCountryCode: { type: String, default: '' },
    // Same as MazInput (options: `sm|md|lg`)
    size: { type: String, default: '' },
    // Countries selected will be at the top of the list - Ex : `preferred-countries="['FR', 'BE', 'DE']`
    preferredCountries: { type: Array, default: () => [] },
    // Only countries selected are in list - Ex : `only-countries="['FR', 'BE', 'DE']`
    onlyCountries: { type: Array, default: () => [] },
    // Countries seleted are remove from the list - Ex : `ignored-countries="['FR', 'BE', 'DE']`
    ignoredCountries: { type: Array, default: () => [] },
    // Translate text in component - By default `{ countrySelectorLabel: 'Country code', countrySelectorError: 'Choose country', phoneNumberLabel: 'Phone number', example: 'Example:' }`
    translations: { type: Object, default: () => ({}) },
    // Remove the validation UI state (success border color)
    noValidation: { type: Boolean, default: false },
    // Remove flags in country selector
    noFlags: { type: Boolean, default: false },
    // Remove the number example from the label input
    noExample: { type: Boolean, default: false },
    // Change the height of country item in list
    countriesHeight: { type: Number, default: 30 },
    // Disable use of browser locale to init the country selector (usefull for Nuxt.JS)
    noUseBrowserLocale: { type: Boolean, default: false },
    // Fetch country code via https://ip2c.org/s - Network needed - (Do not use it with default-country-code options)
    fetchCountry: { type: Boolean, default: false },
    // The country selector is not shown, you can validate your phone number with the country code set
    noCountrySelector: { type: Boolean, default: false },
    // Show the country phone code in the list
    showCodeOnList: { type: Boolean, default: false },
    // Enable the dark mode
    dark: { type: Boolean, default: false },
    // Use color
    color: { type: String, default: 'primary' },
    // Set placholder of phone number input
    placeholder: { type: String, default: '' },
    // hint message shown on phone number text field
    hint: { type: String, default: '' },
    // set the position of countries list (ex: `top`, `top right`, `bottom right`)
    position: { type: String, default: 'left bottom' }
  },
  emits: ['update:modelValue', 'update', 'input', 'focus', 'blur', 'change', 'clear'],
  setup (props, ctx) {
    const uniqueId = uuidv4()

    const CountrySelector = ref<typeof MazSelect | null>(null)
    const PhoneNumberInput = ref<typeof MazInput | null>(null)

    const results = ref<{
      countryCode: string;
      countryCallingCode: string;
      nationalNumber: string;
      isValid: boolean;
      type: string;
      formatInternational: string;
      formatNational: string;
      uri: string;
      e164: string;
    }>({} as {
      countryCode: string;
      countryCallingCode: string;
      nationalNumber: string;
      isValid: boolean;
      type: string;
      formatInternational: string;
      formatNational: string;
      uri: string;
      e164: string;
    })
    const countryCode = ref<CountryCode>(props.defaultCountryCode as CountryCode)
    const lastKeyPressed = ref<number | null>(null)
    const asYouTypeNumber = ref<string | null>(props.defaultPhoneNumber)

    const t = computed(() => {
      return {
        ...locales,
        ...props.translations
      }
    })

    const callingCode = computed(() => {
      const getDialCode = (code) => {
        const result = countriesSorted.value.find(m => m.iso2 === code)
        return result ? result.dialCode : null
      }
      return countryCode.value ? `+${getDialCode(countryCode.value) || getCountryCallingCode(countryCode.value)}` : null
    })
    // input states
    const shouldChooseCountry = computed(() => !countryCode.value && !!asYouTypeNumber.value)
    const isValid = computed(() => results.value?.isValid)
    const hasEmptyPhone = computed(() => asYouTypeNumber.value === '' || !asYouTypeNumber.value)
    // hint values
    const phoneNumberExample = computed(() => {
      const phoneNumber = countryCode.value ? getExampleNumber(countryCode.value, examples) : null
      return phoneNumber ? phoneNumber.formatNational() : null
    })
    const hintValue = computed(() => {
      return props.noExample || !phoneNumberExample.value
        ? null
        : hasEmptyPhone.value || isValid.value ? null : `${t.value.example} ${phoneNumberExample.value}`
    })

    const countriesSorted = computed(() => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let countriesFiltered: any = countries

      if (props.onlyCountries.length) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        countriesFiltered = props.onlyCountries.map((country: any) => countries.find(item => item.iso2.includes(country)))
      }

      if (props.ignoredCountries.length) {
        countriesFiltered = countriesFiltered.filter(item => !props.ignoredCountries.includes(item.iso2))
      }

      if (props.preferredCountries.length) {
        countriesFiltered = [
          ...props.preferredCountries.map(country => countriesFiltered.find(item => item.iso2.includes(country))),
          ...countriesFiltered.filter(item => !props.preferredCountries.includes(item.iso2))
        ]
      }

      return countriesFiltered
    })

    async function buildResults (phoneNumber, noAutoUpdateCountryCode) {
      const backSpacePressed = lastKeyPressed.value === 8
      await nextTick()
      const lastCharacOfPhoneNumber = asYouTypeNumber.value ? asYouTypeNumber.value.slice(asYouTypeNumber.value.length - 1) : false
      if (backSpacePressed && lastCharacOfPhoneNumber && (lastCharacOfPhoneNumber === ')')) {
        asYouTypeNumber.value = asYouTypeNumber.value?.slice(0, -1) || ''
      }
      results.value = await getResultsFromPhoneNumber(phoneNumber, countryCode.value)
      asYouTypeNumber.value = await getAsYouTypeFormat(
        phoneNumber,
        countryCode.value
      )
      if (!noAutoUpdateCountryCode && results.value && results.value.countryCode && (countryCode.value !== results.value.countryCode)) {
        setCountryCode(results.value.countryCode, false)
      }
      // sent when the user tape
      // @arg Object with all parsed values
      ctx.emit('update', results.value)
      const { isValid: isValidResults, e164: e164Results } = results.value
      const valueToEmit = isValidResults ? e164Results : asYouTypeNumber.value
      if (!valueToEmit && valueToEmit === props.modelValue) return
      // sent when the user tape
      // @arg Phone number value formatted in e164 format (international format)
      ctx.emit('update:modelValue', valueToEmit)
    }
    async function setCountryCode (locale, focus) {
      const countryAvailable = await isCountryAvailable(locale)
      if (focus) {
        await focusPhoneNumberInput()
        if (asYouTypeNumber.value && asYouTypeNumber.value.includes('+')) asYouTypeNumber.value = null
      }
      if (countryAvailable && locale) {
        countryCode.value = locale
        await buildResults(asYouTypeNumber.value, true)
      }
    }
    async function focusCountrySelector () {
      await nextTick()
      CountrySelector.value?.$el.querySelector('input')?.focus()
    }
    async function focusPhoneNumberInput () {
      await nextTick()
      PhoneNumberInput.value?.$el.querySelector('input')?.focus()
    }

    onMounted(async () => {
      try {
        if (!props.defaultPhoneNumber && props.modelValue) {
          await buildResults(props.modelValue, false)
        }
        if (props.defaultCountryCode && props.fetchCountry) {
          throw new Error('MazPhoneNumberInput: Do not use \'fetch-country\' and \'default-country-code\' options in the same time')
        }
        if (props.defaultCountryCode && props.noUseBrowserLocale) {
          throw new Error('MazPhoneNumberInput: If you use a \'default-country-code\', do not use \'no-use-browser-locale\' options')
        }
        if (props.defaultCountryCode) return
        const locale = props.fetchCountry
          ? await fetchCountryCode()
          : props.noUseBrowserLocale ? null : await browserLocale()
        if (locale) {
          await setCountryCode(locale, false)
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        throw new Error(err)
      }
    })

    watch(() => props.defaultPhoneNumber, (newPN, oldPN) => {
      if (newPN === oldPN) return
      buildResults(newPN, false)
    })
    watch(() => props.defaultCountryCode, (newValue, oldValue) => {
      if (!newValue || (newValue === oldValue)) return
      setCountryCode(newValue, false)
    })

    return {
      uniqueId,

      CountrySelector,
      PhoneNumberInput,

      results,
      countryCode,
      lastKeyPressed,
      asYouTypeNumber,

      t,
      callingCode,
      shouldChooseCountry,
      isValid,
      hasEmptyPhone,
      phoneNumberExample,
      hintValue,
      countriesSorted,

      buildResults,
      setCountryCode,
      focusCountrySelector,
      focusPhoneNumberInput
    }
  }
})
