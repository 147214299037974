import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-87c6ff18"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "lPage" }
const _hoisted_2 = { class: "lAuth_row" }
const _hoisted_3 = { class: "lAuth_col" }
const _hoisted_4 = { class: "lAuth_col -form lAuth_fullCol -fullWidth" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_as_header_mobile = _resolveComponent("as-header-mobile")!
  const _component_profile = _resolveComponent("profile")!
  const _component_mq_responsive = _resolveComponent("mq-responsive")!
  const _component_as_breadcrumb = _resolveComponent("as-breadcrumb")!
  const _component_as_header_main = _resolveComponent("as-header-main")!
  const _component_as_menu = _resolveComponent("as-menu")!
  const _component_as_banner = _resolveComponent("as-banner")!
  const _component_as_banners_top = _resolveComponent("as-banners-top")!
  const _component_as_spacer = _resolveComponent("as-spacer")!
  const _component_as_footer = _resolveComponent("as-footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_mq_responsive, {
      class: "lUserEdit -edit -mobile",
      target: "mobile-tablet"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_as_header_mobile, {
          title: _ctx.$t('edit'),
          back: "",
          nav: false
        }, null, 8, ["title"]),
        _createVNode(_component_profile, {
          class: "lUserEdit_profile",
          classTitle: "lAuth_title"
        })
      ]),
      _: 1
    }),
    _createVNode(_component_mq_responsive, {
      class: "lAuth -edit bg-blue",
      target: "desktop"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_as_header_main, null, {
          breadcrumb: _withCtx(() => [
            _createVNode(_component_as_breadcrumb, {
              title: _ctx.$t(_ctx.title)
            }, null, 8, ["title"])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_as_menu, {
              slot: "menu",
              menu: _ctx.menu,
              type: "sidebar"
            }, null, 8, ["menu"]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bannersSidebar, (banner, i) => {
              return (_openBlock(), _createBlock(_component_as_banner, {
                key: `bannerSidebar${i}`,
                banner: banner,
                platform: _ctx.bannersPlatforms.desktop,
                position: _ctx.bannersPosition,
                placement: _ctx.bannersPlacements.sidebar
              }, null, 8, ["banner", "platform", "position", "placement"]))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_as_banners_top, {
              banners: _ctx.bannersTop,
              position: _ctx.bannersPosition,
              placement: _ctx.bannersPlacements.top,
              platform: _ctx.bannersPlatforms.desktop,
              class: "-fullWidth"
            }, null, 8, ["banners", "position", "placement", "platform"]),
            _createVNode(_component_profile, {
              classTitle: "lAuth_title",
              title: _ctx.title
            }, null, 8, ["title"]),
            _createVNode(_component_as_banners_top, {
              banners: _ctx.bannersBottom,
              position: _ctx.bannersPosition,
              placement: _ctx.bannersPlacements.bottom,
              platform: _ctx.bannersPlatforms.desktop,
              class: "-fullWidth"
            }, null, 8, ["banners", "position", "placement", "platform"])
          ])
        ]),
        _createVNode(_component_as_spacer),
        _createVNode(_component_as_footer)
      ]),
      _: 1
    })
  ]))
}