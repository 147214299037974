
import { defineComponent } from 'vue'
import Time from '@web/common/Time'

import { Form as VForm, Field as VField } from 'vee-validate'
import SettingsContent from '@web/components.v2/Settings/Content.vue'
import AvatarUpload from '@web/components.v2/Settings/Profile/AvatarUpload.vue'
import PhoneInput from '@web/components.v2/Settings/Profile/PhoneInput.vue'
import AcNotice from '@ui-kit/components/AcNotice.vue'
import AcInput from '@ui-kit/components/AcInput.vue'
import AcSelect from '@ui-kit/components/AcSelect.vue'
import AcRadio from '@ui-kit/components/AcRadio.vue'
import AcButton from '@ui-kit/components/AcButton.vue'

import useErrors from '@web/composition/useErrors'
import useUserProfile from '@web/composition/useUserProfile'

export default defineComponent({
  name: 'Profile',
  components: {
    VForm,
    VField,
    SettingsContent,
    AvatarUpload,
    PhoneInput,
    AcNotice,
    AcInput,
    AcSelect,
    AcRadio,
    AcButton
  },
  props: {
    title: {
      type: String,
      default: () => ''
    },
    update: {
      type: Boolean,
      default: false
    }
  },
  emits: ['submit'],
  setup (props, ctx) {
    const {
      serverErrors,
      displayServerErrors,
      getFirstError
    } = useErrors()

    function scrollToError () {
      const errorEl: HTMLElement | null = document.querySelector('.acInput_error')
      const phoneError: HTMLElement | null = document.querySelector('.acPhoneInput_error')
      if (errorEl && errorEl.parentElement) {
        errorEl.parentElement.scrollIntoView()
        errorEl.parentElement.querySelector('input')?.focus()
      } else if (phoneError && phoneError.parentElement) {
        phoneError.parentElement?.scrollIntoView()
        const phoneInput: HTMLInputElement | null = phoneError.parentElement.querySelector('input.acPhoneInput_input')
        phoneInput?.focus()
      }
    }

    return {
      serverErrors,
      getFirstError,
      Time,
      scrollToError,
      ...useUserProfile({
        serverErrors,
        displayServerErrors,
        emit: ctx.emit
      })
    }
  }
})
