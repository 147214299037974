import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, renderSlot as _renderSlot, mergeProps as _mergeProps, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "maz-flex maz-align-center" }
const _hoisted_3 = {
  key: 0,
  class: "country-selector__flag-container maz-mr-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_maz_select = _resolveComponent("maz-select")!
  const _component_maz_input = _resolveComponent("maz-input")!

  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.id,
    class: _normalizeClass([[{ 'maz-is-dark': _ctx.dark }, `maz-phone-number-input--${_ctx.size}`], "maz-base-component maz-phone-number-input maz-flex"])
  }, [
    (!_ctx.noCountrySelector)
      ? (_openBlock(), _createBlock(_component_maz_select, {
          key: 0,
          ref: "CountrySelector",
          "model-value": _ctx.countryCode,
          options: _ctx.countriesSorted,
          placeholder: _ctx.t.countrySelectorLabel,
          search: "",
          position: _ctx.position,
          "search-placeholder": _ctx.t.countrySelectorSearchPlaceholder,
          "items-height": _ctx.countriesHeight,
          error: _ctx.shouldChooseCountry,
          hint: _ctx.shouldChooseCountry ? _ctx.t.countrySelectorError : null,
          size: _ctx.size,
          success: _ctx.isValid && !_ctx.noValidation,
          disabled: _ctx.disabled,
          "input-value": _ctx.callingCode,
          "list-width": 250,
          config: {
        labelKey: 'dialCode',
        searchKey: 'name',
        valueKey: 'iso2'
      },
          color: _ctx.color,
          class: _normalizeClass(["country-selector", {
        'no-padding-left': _ctx.noFlags || !_ctx.countryCode
      }]),
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.setCountryCode($event, true)))
        }, {
          default: _withCtx(({ option }) => [
            _createElementVNode("div", _hoisted_2, [
              (!_ctx.noFlags)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createElementVNode("div", {
                      class: _normalizeClass(`maz-flag maz-flag-${option.iso2.toLowerCase()}`)
                    }, null, 2)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.showCodeOnList)
                ? (_openBlock(), _createElementBlock("span", {
                    key: 1,
                    class: _normalizeClass(["country-selector__calling-code maz-flex-fixed maz-text-muted", {
              'maz-text-muted-dark': option.isSelected
            }])
                  }, " +" + _toDisplayString(option.dialCode), 3))
                : _createCommentVNode("", true),
              _createElementVNode("div", {
                class: _normalizeClass(["maz-dots-text maz-flex-1 maz-text-left maz-text-color", {
              'maz-text-white': option.isSelected
            }])
              }, _toDisplayString(option.name), 3)
            ])
          ]),
          _: 1
        }, 8, ["model-value", "options", "placeholder", "position", "search-placeholder", "items-height", "error", "hint", "size", "success", "disabled", "input-value", "color", "class"]))
      : _createCommentVNode("", true),
    (_ctx.countryCode && !_ctx.noFlags && !_ctx.noCountrySelector)
      ? (_openBlock(), _createElementBlock("button", {
          key: 1,
          class: "maz-phone-number-input__country-flag",
          tabindex: "-1",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.focusCountrySelector && _ctx.focusCountrySelector(...args)))
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(`maz-flag maz-flag-${_ctx.countryCode.toLowerCase()}`)
          }, null, 2)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_maz_input, _mergeProps({
      id: _ctx.uniqueId ? `${_ctx.uniqueId}_phone_number` : null,
      ref: "PhoneNumberInput",
      "model-value": _ctx.asYouTypeNumber,
      placeholder: _ctx.placeholder || _ctx.t.phoneNumberLabel,
      hint: _ctx.hint || _ctx.hintValue,
      disabled: _ctx.disabled,
      size: _ctx.size,
      success: _ctx.isValid && !_ctx.noValidation
    }, _ctx.$attrs, {
      clearable: "",
      color: _ctx.color,
      class: ["input-phone-number maz-flex-1", {
        'has-border-radius': _ctx.noCountrySelector
      }],
      onKeydown: _cache[2] || (_cache[2] = (e) => { _ctx.lastKeyPressed = e.keyCode }),
      onFocus: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('focus', $event))),
      onBlur: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('blur', $event))),
      onChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('change', $event))),
      onClear: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('clear', $event))),
      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (_ctx.buildResults($event, false)))
    }), {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "icon-left", { slot: "icon-left" }),
        _renderSlot(_ctx.$slots, "icon-right", { slot: "icon-right" })
      ]),
      _: 3
    }, 16, ["id", "model-value", "placeholder", "hint", "disabled", "size", "success", "color", "class"])
  ], 10, _hoisted_1))
}