
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AcRadio',
  props: {
    checked: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ''
    },
    modelValue: {
      type: [String, Number],
      default: ''
    }
  },
  emits: ['update:modelValue', 'change'],
  setup (props, ctx) {
    function change () {
      ctx.emit('change', props.modelValue)
    }

    return {
      change
    }
  }
})
