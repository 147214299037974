
import { defineComponent, computed } from 'vue'

import MazPhoneNumberInput from '@web/components/maz/MazPhoneNumberInput/index.vue'

import i18n from '@web/plugins/i18n'

export default defineComponent({
  name: 'PhoneInput',
  components: {
    MazPhoneNumberInput
  },
  props: {
    error: {
      type: String,
      default: null
    },
    modelValue: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: i18n.global.t('phone')
    },
    placeholder: {
      type: String,
      default: i18n.global.t('input.phone')
    },
    required: {
      type: Boolean,
      default: false
    },
    preferredCountries: {
      type: Array,
      default: () => ['RU', 'UA', 'KZ', 'BY', 'LT', 'LV', 'PL', 'CZ', 'EE', 'GE', 'AZ']
    },
    defaultCountry: {
      type: String,
      default: 'RU'
    },
    note: {
      type: String,
      default: ''
    },
    position: {
      type: String,
      default: 'left bottom'
    }
  },
  emits: ['update:modelValue'],
  setup (props, ctx) {
    function onInput (payload) {
      ctx.emit('update:modelValue', payload)
    }

    const isError = computed(() => Boolean(props.error))

    return {
      onInput,
      isError
    }
  }
})
