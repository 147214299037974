import { computed, ref, watch } from 'vue'
import i18n from '@web/plugins/i18n'

export default function useAvatarUpload ({ src, change, loading }: { src: string; change: (file: File) => void; loading: boolean }) {
  const preview = ref<string | ArrayBuffer | null>('')

  preview.value = src

  const loadPreview = file => {
    const reader = new FileReader()
    // с addEventListener код падал с ошибкой android 7.1
    reader.onload = () => {
      preview.value = reader.result
    }
    reader.readAsDataURL(file)
  }

  function fileUpload (e) {
    const file = e.target.files && e.target.files[0]
    if (!file) return

    loadPreview(file)
    change(file)
  }

  watch(() => src, newVal => {
    preview.value = newVal
  })

  const btnText = computed(() => {
    if (loading) {
      return i18n.global.t('uploadFile')
    }
    const re = /\/images\/avatar.png$/
    return preview.value && !re.test(String(preview.value)) ? i18n.global.t('uploadOtherPhoto') : i18n.global.t('uploadPhoto')
  })

  return {
    preview,
    btnText,

    fileUpload
  }
}
