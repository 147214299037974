
import { defineComponent } from 'vue'

import Avatar from '@web/components.v2/Settings/Profile/Avatar.vue'

import useAvatarUpload from '@web/composition/useAvatarUpload'

export default defineComponent({
  name: 'AvatarUpload',
  components: {
    Avatar
  },
  props: {
    src: {
      type: String,
      default: () => ''
    },
    loading: {
      type: Boolean,
      default: () => false
    }
  },
  emits: ['change'],
  setup (props, ctx) {
    return {
      ...useAvatarUpload({
        src: props.src,
        loading: props.loading,
        change: file => ctx.emit('change', file)
      })
    }
  }
})
