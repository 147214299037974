
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Avatar',
  props: {
    preview: {
      type: String,
      default: () => ''
    }
  }
})
